import React, { useContext, useEffect, useState } from 'react';
import { SiteSettingsContext } from '../contexts';

export const NumPad = ({setOutput}) => {
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);
    const [number, setNumber] = useState(0);

    const phoneNumArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const calcNumArray = [7, 8, 9, 4, 5, 6, 1, 2, 3];

    const [numPad, setNumpad] = useState(settings.numPad === 'phone' ? phoneNumArray : calcNumArray);

    useEffect(() => {
        setNumpad(settings.numPad === 'phone' ? phoneNumArray : calcNumArray);
    }, [settings.numPad]);

    const pressKey = (key) => {
        if(validateKey(key)) {
            setNumber(parseInt(number) === 0 || number === '' ? key : number.toString() + key);
        }
    }
    const backSpace = () => {
      setNumber(number.toString().slice(0, -1));
    }

    const validateKey = (key) => {
        // no double decimal points
        if (key === '.' && number.toString().indexOf('.') !== -1) {
            return false;
        }

        // no more than 2 decimal points
        if (key !== '' && key !== null && number.toString().indexOf('.') !== -1 && number.toString().split('.')[1].length > 1) {
            return false;
        }

        if ((parseInt(number) === 0 || number === '') && key === '0') {
            return false;
        }

        return true;
    }

    useEffect(() => {
        setOutput(number);
    }, [number]);

    return (
        <section id={'numpad'} className={'grid grid-cols-3 gap-3 text-center text-2xl'}>
            {
                numPad.map((num, idx) => (
                    <div key={idx} className={'p-6 rounded-xl'} onClick={() => pressKey(num.toString())}>{num}</div>
                ))
            }
            <div className={'p-6 rounded-xl'} onClick={() => pressKey('0')}>0</div>
            <div className={'p-6 rounded-xl'} onClick={() => pressKey('.')}>.</div>
            <div className={'p-6 rounded-xl'} onClick={backSpace}><i className="las la-backspace"></i></div>
        </section>
    );
};