import React, { useContext, useEffect } from 'react';
import { TxnContext } from '../../../contexts';

import { DashboardSummary } from '../../../legos/dashboard/DashboardSummary';
import { DashboardTxnDetails } from '../../../legos/dashboard/DashboardTxnDetails';
import { MenuDesktop } from '../../../template/MenuDesktop';
import { AddTxnDesktop } from '../txn-add-web/AddTxnDesktop';

export const DashboardDesktop = () => {
    const { getDailySummary } = useContext(TxnContext);

    useEffect(() => {
        getDailySummary();
    }, [getDailySummary]);

    return (
        <article className={'h-screen overflow-y-auto'}>
            <DashboardSummary />
            <DashboardTxnDetails className={'pb-24 px-6'} />
        </article>
    );
};