import { useContext } from 'react';
import { SiteSettingsContext } from '../../contexts';

export const Version = () => {
    const { onChangeSettings } = useContext(SiteSettingsContext);
    const version = process.env.REACT_APP_VERSION;

    const closePopUp = () => {
        onChangeSettings({
            newVersion: false
        })
    }

    return (
        <div className={'flex justify-center items-center w-full fixed bottom-24 z-30 cursor-pointer'} onClick={closePopUp}>
            <span className={'inline-block bg-amber-400 py-1 px-4 text-black font-bold text-sm rounded-lg'}>ver {version}<span className={'font-light px-2'}>|</span><i className={'la la-times'} /></span>
        </div>
    );
};