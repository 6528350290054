export const CategoriesSelect = ({cats, selectedChoice, setSelectedChoice}) => {

    return (
        <div className={'grid grid-cols-3 gap-3 text-center'}>
            {
                cats.map((cat, index) => (
                    <div key={index} className={'p-6 rounded-xl ' + (index === selectedChoice ? 'dark:bg-primary-dark/30 bg-primary-light/30' : '')} onClick={() => setSelectedChoice(index)}>
                        <div>{cat.emoji}</div>
                        <div className={'text-2xs mt-2'}>{cat.category}</div>
                    </div>
                ))
            }
        </div>
    );
};