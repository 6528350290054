import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
export default function Modal({isOpen, closeModal, title, type, children}) {
    let [isOpenModal, setIsOpenModal] = useState(false);
    let [emoji, setEmoji] = useState('🫐');
    const [modalTypeCss, setModalTypeCss] = useState('bg-white dark:bg-gray-700');

    useEffect(() => {
        setIsOpenModal(isOpen);
    }, [isOpen]);

    useEffect(() => {
        switch (type) {
            case 'success':
                setModalTypeCss('bg-emerald-200/80 dark:bg-emerald-700/80 border border-emerald-500');
                setEmoji('🍏');
                break;
            case 'info':
                setModalTypeCss('bg-blue-200/80 dark:bg-blue-700/80 border border-blue-500');
                setEmoji('🫐');
                break;
            case 'warning':
                setModalTypeCss('bg-amber-200/80 dark:bg-yellow-700/80 border border-yellow-500');
                setEmoji('☢️️');
                break;
            case 'error':
            case 'danger':
                setModalTypeCss('bg-red-200/80 dark:bg-red-700/80 border border-red-500');
                setEmoji('🚩');
                break;
            default:
                setModalTypeCss('bg-gray-100 dark:bg-gray-700');
                setEmoji('');
        }
    }, [type]);

    return (
        <>
            <Transition appear show={isOpenModal} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-sm bg-black/5" onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal extended. */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className={modalTypeCss + ' inline-block w-full max-w-md px-6 pb-8 pt-6 my-8 text-left align-middle transition-all transform shadow-xl rounded-2xl relative'}>
                                <span className={'inline-block text-6xl absolute -top-6'}>{emoji}</span>
                                <div className={'flex justify-between align-middle'}>
                                    <Dialog.Title
                                        as="h3"
                                        className={'text-2xl font-medium text-gray-900 leading-normal dark:text-gray-100 ' + (emoji !== '' ? 'mt-8' : '')}>
                                        {title}
                                    </Dialog.Title>
                                    <i className={'la la-times la-2x dark:text-gray-100 text-gray-900 cursor-pointer'} onClick={closeModal} />
                                </div>

                                <div className="mt-2">
                                    <div className="text-sm text-gray-700 dark:text-gray-100">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
