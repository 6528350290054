import React, { useEffect, useState } from 'react';
import { isoLocalDate } from '../../../helpers/Helpers';
import Modal from '../../template/Modal';
import AddEntryForm from '../addEntry/AddEntryForm';
import { TxnContext } from '../../contexts/TxnContext';

const TransactionModal = ({ transaction, isOpen, setIsOpen, selectedSource, sources }) => {
    const RESET = {
        id: 0,
        tx_date: isoLocalDate(new Date()),
        tx_amount: '',
        description: '',
        tx_type: 1,
        source: 1,
        tx_cat: '0',
        txnModalOpen: false
    };

    const { txn, setTxn, getDailySummary} = React.useContext(TxnContext);

    const [inputs, setInputs] = useState(transaction);
    const [errors, setErrors] = useState({});

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal, setModal] = useState({
        title: '',
    });

    useEffect(() => {
        if (isOpen) {
            openAddEntry();
        }
    }, [isOpen]);

    function openModal(modal) {
        setModal(modal);
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
        setIsOpen(false);
        setTxn(RESET);
    }

    const openAddEntry = (e) => {
        openModal({
            title: 'Transaction',
            content: 'Lorem ipsum',
            buttons: ['Submit', 'Delete'],
        });
        //return false
    };

    const handleSubmit = () => {
        let api_base = process.env.REACT_APP_API_BASE;

        fetch(api_base + 'transaction', {
            method: inputs.id === 0 ? 'POST' : 'PUT',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(inputs),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 'success') {
                    //modal
                    closeModal();
                    getDailySummary();
                }
            })
            .catch((error) => {
                console.log(error);
                setErrors({
                    status: {
                        type: 'danger',
                        message: 'Something went wrong',
                        error: error,
                    },
                });
            });
    };

    const handleDelete = () => {
        if (window.confirm('Are sure want to delete this transaction?')) {
            let api_base = process.env.REACT_APP_API_BASE;

            fetch(api_base + 'transaction/' + inputs.id, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === 'success') {
                        //modal
                        closeModal();
                        getDailySummary();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setErrors({
                        status: {
                            type: 'danger',
                            message: 'Something went wrong',
                            error: error,
                        },
                    });
                });
        }
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} closeModal={closeModal} title={modal.title}>
                {' '}
                <AddEntryForm
                    inputs={inputs}
                    setInputs={setInputs}
                    errors={errors}
                    selectedSource={selectedSource}
                    sources={sources}
                    handleSubmit={handleSubmit}
                    handleDelete={handleDelete}
                />
            </Modal>
        </>
    );
};
export default TransactionModal;
