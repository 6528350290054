import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivacyToggle } from './PrivacyToggle';
import { TxnContext } from '../contexts';

const Header = () => {
    const { getDailySummary, isTxnLoading } = useContext(TxnContext);
    const navigate = useNavigate();

    return (
        <>
            <div className={'flex justify-between items-center sticky top-0 z-10 bg-white dark:bg-gray-800'}>
                <div className={'flex items-center'} onClick={() => navigate(-1)}>
                    <div className={'p-3'}>
                        <img src={'img/nsd.jpg'} className={'rounded-full  border border-gray-700 dark:border-gray-200'}
                            style={{ width: '32px' }} />
                    </div>
                    <div className={'text-2xs'}>
                        <h2>Welcome back</h2>
                        <h3 className={'font-bold'}>Nipuna Dodantenna</h3>
                    </div>
                </div>
                <div className={'flex gap-2 justify-end text-right p-3'}>
                    <i className={'la la-refresh p-3 rounded-full bg-gray-200 dark:bg-gray-700 cursor-pointer ' + (isTxnLoading ? 'la-spin' : '')} onClick={() => getDailySummary()} />
                    <PrivacyToggle className={''} />
                </div>
            </div>
        </>
    );
};

export default Header;
