export const SummaryReport = () => {
    return (
        <>
            <h1 className={'text-center pt-12 pb-8 text-2xl'}>Monthly Summary Report</h1>
            <svg height="138" width="100%">
                <defs>
                    <linearGradient id="grad0" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stop-color="#36A4B955"></stop>
                        <stop offset="100%" stop-color="#36A4B900"></stop>
                    </linearGradient>
                </defs>

                <polygon points={'0,138 0,58 2,58 5,69 7,69 10,80 12,90 14,101 17,112 19,90 22,112 24,112 26,122 29,122 31,122 34,122 36,122 38,122 41,122 43,122 46,133 48,133 50,122 53,112 55,101 58,90 60,69 62,69 65,80 67,69 70,80 72,69 74,69 77,80 79,69 82,80 84,58 86,48 89,48 91,37 94,48 96,48 98,48 101,58 103,48 106,37 108,5 110,16 113,16 115,26 118,26 120,26 122,16 125,26 127,48 130,48 132,58 134,58 137,48 139,48 142,48 144,48 144,138'} style={{
                    fill: 'url(#grad0)',
                    stroke: 'transparent',
                    strokeLinejoin: 'round' }}></polygon>
                <polyline points={'0,58 2,58 5,69 7,69 10,80 12,90 14,101 17,112 19,90 22,112 24,112 26,122 29,122 31,122 34,122 36,122 38,122 41,122 43,122 46,133 48,133 50,122 53,112 55,101 58,90 60,69 62,69 65,80 67,69 70,80 72,69 74,69 77,80 79,69 82,80 84,58 86,48 89,48 91,37 94,48 96,48 98,48 101,58 103,48 106,37 108,5 110,16 113,16 115,26 118,26 120,26 122,16 125,26 127,48 130,48 132,58 134,58 137,48 139,48 142,48 144,48'} style={{
                    fill: 'none',
                    stroke: '#36A4B9',
                    strokeWidth: '3',
                    strokeLinejoin: 'round'
                }}></polyline>
            </svg>
        </>
    );
};