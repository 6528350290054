import React, { useEffect, useState } from 'react';
import ThemeToggle from './ThemeToggle';
import { Link, useLocation } from 'react-router-dom';
import TransactionModal from '../extended/modals/TransactionModal';
import { TxnContext } from '../contexts/TxnContext';
import Menu from './Menu';

const Footer = () => {
    const { txn, setTxn } = React.useContext(TxnContext);
    const thisPage = useLocation();

    const [wallets, setWallets] = useState([
        {
            id: 0,
            shortName: 'Select a source',
        },
        {
            id: 1,
            shortName: 'NSB',
        },
        {
            id: 2,
            shortName: 'BOC',
        },
        {
            id: 3,
            shortName: 'Cash',
        },
        {
            id: 4,
            shortName: 'FD',
        },
    ]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const openTxnModal = (state) => {
        setTxn({
            ...txn,
            txnModalOpen: state,
        });
    };

    useEffect(() => {
        const root = document.getElementsByTagName('html')[0].classList;
        if (menuIsOpen) {
            root.add('overflow-hidden');
        } else {
            root.remove('overflow-hidden');
        }
    }, [menuIsOpen]);

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
        //return false
    };


    return (
        <>
            {txn.txnModalOpen && (
                <TransactionModal
                    transaction={txn}
                    isOpen={txn.txnModalOpen}
                    setIsOpen={openTxnModal}
                    selectedSource={txn.source}
                    sources={wallets}
                />
            )}

            {
                menuIsOpen
                    ? <div className={'backdrop-blur-lg fixed z-20 top-0 left-0 w-full min-h-screen overflow-hidden flex flex-col justify-end'}>
                        <Menu toggleMenu={toggleMenu} />
                    </div>
                    : <></>
            }
            <div className={'fixed z-30 left-0 w-full bottom-3 px-3'}>
                <div className={'flex justify-around items-center py-3 bg-gray-200 dark:bg-gray-700 rounded-3xl sm:w-2/3 md:w-1/2 2xl:w-1/3 mx-auto'}>
                    <Link to={'/'}>
                        <i className={'la la-home la-fw text-xl'+(thisPage.pathname === '/' ? ' text-primary' : '')} />
                    </Link>
                    <Link to={'/reports'}>
                        <i className={'la la-chart-bar la-fw text-xl'+(thisPage.pathname === '/reports' ? ' text-primary' : '')} />
                    </Link>
                    <Link to={'/txn'}>
                    <button className={'la la-plus text-xl inline-block px-4 py-3 rounded-full cursor-pointer bg-primary text-white'} />
                    </Link>
                    <ThemeToggle className={'text-xl'} disabled={txn.txnModalOpen} />
                    <button className={'cursor-pointer la text-xl la-fw' + (menuIsOpen ? ' la-times' : ' la-bars') + (txn.txnModalOpen ? ' text-gray-400' : '')} onClick={() => toggleMenu()} title={'Menu'} disabled={txn.txnModalOpen} />
                </div>
            </div>

        </>
    );
};

export default Footer;
