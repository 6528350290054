import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

export const DropDown = ({list}) => {
    const [selectedPerson, setSelectedPerson] = useState(list[0]);

    return (
        <div className={'relative'}>
            <Listbox value={selectedPerson} onChange={setSelectedPerson}>
                <Listbox.Button
                    className={'relative w-full cursor-default rounded-full bg-white dark:bg-gray-700 py-2 pl-4 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'}
                >
                    <span className="block truncate">{selectedPerson.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                      <i className={'la la-angle-down'} />
                    </span>
                </Listbox.Button>
                <Listbox.Options
                    className={'absolute mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white dark:bg-gray-700 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'}
                >
                    {list.map((person) => (
                        <Listbox.Option
                            key={person.id}
                            value={person}
                            disabled={person.unavailable}
                            as={'div'}
                            className={({ active }) =>
                                `relative cursor-pointer select-none py-3 pl-10 pr-4 ${
                                    active ? 'bg-cyan-100 dark:bg-cyan-400 text-cyan-900' : ''
                                }`
                            }
                        >
                            {
                                ({active, selected}) => (
                                    <>
                                        {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                              <i className={'la la-check'} />
                                            </span>
                                                    ) : null}
                                        {person.name}
                                    </>
                                )
                            }

                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};