import React from 'react';
import LoginForm from './LoginForm';
import ThemeToggle from '../../../template/ThemeToggle';

export const Login = () => {
    return (
        <div
            className={
                'flex items-center justify-center flex-col min-h-screen dark:bg-gray-800 dark:text-gray-100 transition-all'
            }>
            <LoginForm />
            <div className={'bg-gray-100 dark:bg-gray-700 px-4 py-2 rounded-full'}>
                <ThemeToggle className={'text-xl'} disabled={false} />
            </div>
        </div>
    );
}