import React from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

const Toggle = ({ className, disabled }) => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    return (
        <button
            className={'inline-block text-center transition duration-500 ease-in-out rounded-full cursor-pointer ' + className}
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            disabled={disabled}
        >
            {theme === 'dark' ? (
                <i className={'text-gray-500 dark:text-gray-400 la-fw la la-moon'} />
            ) : (
                <i className={'text-gray-500 dark:text-gray-400 la-fw la la-sun'} />
            )}
        </button>
    );
};

export default Toggle;
