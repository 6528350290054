import React, { useContext } from 'react';
import { SiteSettingsContext } from '../contexts';

export const PrivacyToggle = ({className}) => {
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);

    return (
        <div
            className={'inline-block '+className}
            onClick={() => onChangeSettings({privacy: !settings.privacy})}
            title={'Privacy Toggle'}>
                <i className={'la p-3 rounded-full cursor-pointer ' + (settings.privacy ? 'la-lock-open bg-gray-200 dark:bg-gray-700' : 'la-lock bg-primary-light dark:bg-primary-dark') } />
        </div>
    );
};