export const RichSelect = ({isEnabled, toggleShow, children}) => {
    const isMobile = navigator.userAgentData.mobile;

    const toggleDelayed = () => {
        toggleShow(isEnabled === 0 ? 1 : 2); // 0 - init, 1 - show, 2 - hide
    }

    return (
        <div className={isEnabled}>
            <div className={(isMobile ? 'fixed' : 'absolute') + ' w-full h-full top-0 left-0 right-0 bottom-0 bg-black/20 z-20 ' + (isEnabled === 1 ? 'block' : 'hidden')} onClick={toggleDelayed} />
            <div className={(isMobile ? 'fixed' : 'absolute') + ' bottom-0 z-30 w-full mx-auto no-select rounded-t-2xl bg-white dark:bg-gray-800 px-6 py-5 ' + (isEnabled === 1  ? 'rich-select' : (isEnabled === 2 ? 'rich-select-hide' : 'rich-select-init hidden'))}>
                <div className={'mx-auto bg-gray-300 dark:bg-gray-600 mb-5 rounded-full'} style={{ height: '5px', width: '50px' }} />
                {children}
            </div>
        </div>
    );
};