export const handleSubmit = (url_stub, method, inputs, callback_success, callback_error) => {
    let api_base = process.env.REACT_APP_API_BASE;

    fetch(api_base + url_stub, {
        method: method,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(inputs),
    })
        .then((res) => res.json())
        .then((result) => {
            if (result.status === 'success') {
                callback_success();
            } else {
                callback_error(result);
            }
        })
        .catch((error) => {
            callback_error(error);
        });
};