import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './helpers/PrivateRoute';
import NotFound from './components/template/NotFound';
import {
    DashboardIndex,
    TransferFunds,
    Reports,
    MusicPlayer,
    Login,
    AddTxn,
    Test2,
    DashboardDesktop,
} from './components/pages';

import { ThemeProvider, SiteSettingsProvider, TxnProvider } from './components/contexts';
import { MenuDesktop } from './components/template/MenuDesktop';
import { AddTxnDesktop } from './components/pages/desktop/txn-add-web/AddTxnDesktop';

export default function App() {
    const isMobile = navigator.userAgentData.mobile;

    const common = (
        <>
            <Route path={'/login'} element={<Login />} />
            <Route path={'/player'} element={<MusicPlayer />} />
            <Route path={'/test'} element={<Test2 />} />
            <Route path="*" element={<NotFound />} />
        </>
    );

    const mobile = (
        <Routes>
            <Route path={'/'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <DashboardIndex />
            </PrivateRoute>} />

            <Route path={'/reports'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <Reports />
            </PrivateRoute>} />

            <Route path={'/transfer'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <TransferFunds />
            </PrivateRoute>} />

            <Route path={'/txn/:id?'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <AddTxn />
            </PrivateRoute>} />

            <Route path={'/txn/:id?'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <AddTxn />
            </PrivateRoute>} />
        </Routes>
    );

    const DesktopComp = () => {
        return (
            <div className={'grid grid-cols-4'}>
                <div className={'flex gap-8 flex-col border-r dark:border-gray-700'} style={{ flex: 1 }}>
                    <MenuDesktop />
                </div>
                <div className={'col-span-2 border-r dark:border-gray-700'}>
                    <Routes>
                        <Route path="/:uid?" element={<DashboardDesktop />} />
                        <Route path="/reports/:uid?" element={<Reports />} />
                    </Routes>
                </div>
                <div className={''}>
                    <Routes>
                        <Route path="/" element={<AddTxnDesktop />} />
                    </Routes>
                </div>
            </div>
        )
    }

    const desktop = (
        <Routes>
            <Route path="/:uid?/:id?/*" element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                <DesktopComp />
            </PrivateRoute>} />
        </Routes>
    );

    const auth = (
        <Routes>
            <Route path={'/:id?'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                {isMobile ? mobile : desktop}
            </PrivateRoute>} />
            <Route path={'/login'} element={<Login />} />
        </Routes>
    );

    return (
        <ThemeProvider>
            <TxnProvider>
                <SiteSettingsProvider>
                    <BrowserRouter basename={''}>
                        <Routes>
                            <Route path={'/login'} element={<Login />} />
                        </Routes>
                        {isMobile ? mobile : desktop}
                    </BrowserRouter>
                </SiteSettingsProvider>
            </TxnProvider>
        </ThemeProvider>
    );
}
