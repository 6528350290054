import React, { useCallback, useState } from 'react';
import { isoLocalDate } from '../../helpers/Helpers';

export const TxnContext = React.createContext();

export const TxnProvider = ({ initialTheme, children }) => {
    const RESET = {
        method: 'post',
        tx_date: isoLocalDate(new Date()),
        tx_amount: '',
        description: '',
        tx_type: 1,
        source: 1,
        tx_cat: 1,
        loan_reim: 0
    };

    const [txn, setTxn] = React.useState(RESET);
    const [summary, setSummary] = useState({
        spent_this_month: 0,
        earned_this_month: 0,
        spent_last_month: 0,
        daily_summary: {},
    });
    const [isTxnLoading, setIsTxnLoading] = React.useState(false);

    const getDailySummary = useCallback(() => {
        let api_base = process.env.REACT_APP_API_BASE;

        setIsTxnLoading(true);

        fetch(api_base + 'transaction')
            .then((res) => res.json())
            .then((sum) => {
                setSummary(sum);
            })
            .finally(() => setIsTxnLoading(false));
    }, []);

    const resetTxn = () => {
        setTxn(RESET);
    }

    return (
        <TxnContext.Provider value={{ txn, setTxn, resetTxn, getDailySummary, summary, isTxnLoading }}>
            {children}
        </TxnContext.Provider>
    );
};