import React, { useState } from 'react';

const getInitialPrivacy = () => {
    let version = process.env.REACT_APP_VERSION;
    let settingsInit = {
        privacy: false,
        version,
        newVersion: false,
        numPad: 'calc' // [calc | phone]
    };

    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('wallet-settings');

        if (typeof storedPrefs === 'string') {
            try {
                settingsInit = JSON.parse(storedPrefs);

                if (settingsInit.version !== version) {
                    console.log('We have a new version ' + version);

                    settingsInit = {
                        ...settingsInit,
                        version,
                        newVersion: true
                    }
                }
            } catch(e) {
                // error in the above string (in this case, yes)!
                console.warn('Settings loading error.');
            }
        }
    }

    return settingsInit;
};

export const SiteSettingsContext = React.createContext();

export const SiteSettingsProvider = ({ initialPrivacy, children }) => {
    const [settings, setSettings] = React.useState(getInitialPrivacy);

    const rawSetPrivacy = (rawPrivacy) => {
        localStorage.setItem('wallet-settings', JSON.stringify(rawPrivacy));
    };

    if (initialPrivacy) {
        rawSetPrivacy(initialPrivacy);
    }

    React.useEffect(() => {
        rawSetPrivacy(settings);
    }, [settings]);

    const onChangeSettings = (newSettings) => {
        setSettings({
            ...settings,
            ...newSettings
        });
    }

    return (
        <SiteSettingsContext.Provider value={{ settings, onChangeSettings }}>
            {children}
        </SiteSettingsContext.Provider>
    );
};