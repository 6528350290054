import { useContext } from 'react';

import { Dashboard } from '../mobile/dashboard-mob/Dashboard';
import { DashboardDesktop } from '../desktop/dashboard-web/DashboardDesktop';
import { Version } from '../../extended/modals/Version';

import { SiteSettingsContext } from '../../contexts';

export const DashboardIndex = () => {
    const isMobile = navigator.userAgentData.mobile;
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);

    return (
        <>
            {isMobile ? <Dashboard /> : <DashboardDesktop />}
            {settings.newVersion && <Version />}
        </>
    );
};