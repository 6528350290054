import React, { useRef, useState } from 'react';

export const Calculator = ({closeModal, evalReturn, setCalcOut, calcOut}) => {
    const inputRef = useRef();
    const [calcInput, setCalcInput] = useState(calcOut);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const operate = (operand) => {
        let currentInput = calcInput;

        if (currentInput !== '') {
            if (currentInput.at(-1).match(/[-+/*]/)) {
                currentInput = currentInput.slice(0, -1);
            }

            setCalcInput(currentInput.toString() + operand.toString());
        }

        inputRef.current.focus();
    }

    const calcInputChg = (e) => {
        const currentInput = e.target.value.toString();

        setError(false);
        setErrorMsg('');

        if (currentInput !== '' && !currentInput.at(-1).match(/[-+/*]|\d/)) {
            return;
        }

        setCalcInput(currentInput);
    }

    const equal = () => {
        let currentInput = calcInput;

        if (currentInput !== '') {
            if (!currentInput.at(-1).match(/\d/)) {
                inputRef.current.focus();
                setError(true);
                setErrorMsg('Unfinished expression');

                return;
            }

            const evalOut = eval(currentInput);

            if (evalOut < 0) {
                inputRef.current.focus();
                setError(true);
                setErrorMsg('Minus value encountered');

                return;
            }

            evalReturn(evalOut);
            setCalcOut(currentInput);
            closeModal();
        }
    }

    const clear = () => {
        setCalcInput('');
        inputRef.current.focus();
        setError(false);
        setErrorMsg('');
    }

    return (
        <section className={'pt-4'}>
            <div className={'flex items-center w-full rounded-md bg-gray-300 dark:bg-gray-600 border border-gray-500 dark:border-gray-500 ' + (error ? '!border-red-500' : '')}>
                <input ref={inputRef}
                      className={'bg-transparent outline-0 text-right py-3 w-full'}
                      step='.01'
                      pattern='^\d*(\.\d{0,2})?$' type={'text'} value={calcInput} onChange={calcInputChg}
                      autoFocus={true} />
                <span className='material-symbols-rounded px-2 h-full py-3' onClick={clear}>backspace</span>
            </div>

            <div className={'text-red-500 mt-1'}>{errorMsg}</div>

            <div className={'flex justify-end'}>
                <div className={'calc-btn'} onClick={()=>operate('/')}>÷</div>
                <div className={'calc-btn'} onClick={()=>operate('*')}>×</div>
                <div className={'calc-btn'} onClick={()=>operate('-')}>-</div>
                <div className={'calc-btn'} onClick={()=>operate('+')}>+</div>
                <div className={'calc-btn !bg-primary-dark'} onClick={()=>equal()}>=</div>
            </div>
        </section>
    );
};