import { Fragment, useEffect, useState } from 'react';
import { getCached } from '../../../use-cases/cache/get-cached';
import './forms.scss';

const AddEntryForm = ({
    inputs,
    setInputs,
    handleSubmit,
    handleDelete,
    selectedSource,
    sources,
}) => {
    const [categories, setCategories] = useState([]);

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        getCached('categories', 'categories').then((res) => {
            setCategories(res);
        });
        setInputs({
            ...inputs,
            source: selectedSource ?? 1,
        });
    }, []);

    return (
        <>
            <div className={'mt-12 mb-0'}>
                <div className={'grid grid-cols-2 gap-2'}>
                    <div className={''}></div>
                    <div className={''}>
                        <label className={'mb-1 inline-block'}>Date</label>{' '}
                        <input
                            type={'date'}
                            name={'tx_date'}
                            className={'input-box'}
                            onChange={handleChange}
                            value={inputs.tx_date}
                        />
                    </div>
                </div>
                <label className={'mb-1 inline-block'}>Amount</label>{' '}
                <input
                    type="number"
                    step={'0.01'}
                    className={'input-box'}
                    placeholder={'0.00'}
                    name={'tx_amount'}
                    onChange={handleChange}
                    value={inputs.tx_amount}
                    autoFocus
                />{' '}
                <label className={'mt-3 mb-1 inline-block'}>Description</label>{' '}
                <input
                    type="text"
                    className={'input-box'}
                    placeholder={'Description'}
                    name={'description'}
                    onChange={handleChange}
                    value={inputs.description}
                />
                <div className={'grid grid-cols-3 gap-2'}>
                    <div className={'col-span-2'}>
                        <label className={'mt-3 mb-1 inline-block'}>Type</label>
                        <div className={'check-group flex justify-between'}>
                            <input
                                name={'tx_type'}
                                value={0}
                                id={'income'}
                                type={'radio'}
                                onChange={handleChange}
                                checked={inputs.tx_type == "0"}
                            />{' '}
                            <label className={'px-3 py-2 flex-grow'} htmlFor={'income'}><i className={'la la-download'}/> Income</label>{' '}
                            <input
                                name={'tx_type'}
                                value={1}
                                id={'expenditure'}
                                type={'radio'}
                                onChange={handleChange}
                                checked={inputs.tx_type == "1"}
                            />{' '}
                            <label className={'px-3 py-2 flex-grow'} htmlFor={'expenditure'}><i className={'la la-upload'}/> Expense</label>
                        </div>
                    </div>
                    <div className={''}>
                        <label className={'mt-3 mb-1 inline-block'}>Source</label>{' '}
                        <select
                            name={'source'}
                            className={
                                'p-2 rounded-lg w-full border-2 dark:border-gray-500 focus:border-primary hover:border-primary outline-none dark:bg-gray-700'
                            }
                            onChange={handleChange}
                            value={inputs.source}>
                            {
                                sources.map((source, index) => {
                                    return <option key={index} value={index}>{source.shortName}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className={'col-span-3'}>
                        <label className={'mt-3 mb-2 inline-block'}>Category</label>
                        <div className={'check-group w-auto whitespace-nowrap overflow-x-auto flex'}>
                            {typeof categories !== 'undefined' &&
                                categories.length > 0 &&
                                categories.map((cat, index) => (
                                    <Fragment key={index}>
                                        {' '}
                                        <input
                                            id={cat.category.toLowerCase()}
                                            type={'radio'}
                                            name={'tx_cat'}
                                            onChange={handleChange}
                                            value={cat.id}
                                            checked={inputs.tx_cat == cat.id}
                                        />{' '}
                                        <label className={'flex-grow text-2xl text-center pt-4 pb-2 px-5'} htmlFor={cat.category.toLowerCase()}>
                                            <span>{cat.emoji}</span>
                                            <span className={'text-2xs block'}>{cat.category}</span>
                                        </label>
                                    </Fragment>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="mt-12 flex justify-end">
                    {inputs.id > 0 && (
                        <button
                            type="button"
                            className="px-6 py-2 text-sm font-medium text-red-500 border border-red-500 hover:border-red-600  border border-transparent rounded-full hover:bg-red-600 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={handleDelete}>
                            <i className={'la la-trash'} /> Delete
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn-primary bg-primary py-3 px-10 ml-auto"
                        onClick={handleSubmit}>
                        <i className={'la la-save'} /> Save
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddEntryForm;
