import { useContext, useEffect, useState } from 'react';

import Footer from '../../../template/Footer';
import Header from '../../../template/Header';
import CreditCard from '../../../template/CreditCard';
import { DashboardSummary } from '../../../legos/dashboard/DashboardSummary';
import { DashboardTxnDetails } from '../../../legos/dashboard/DashboardTxnDetails';

import { TxnContext } from '../../../contexts';

export const Dashboard = () => {
    const { getDailySummary } = useContext(TxnContext);

    const [selectedWallet, setSelectedWallet] = useState(null); // index.js of the wallet
    const [wallets, setWallets] = useState([
        {
            name: 'all',
            niceName: 'All',
            shortName: 'Select a source',
            number: '',
            expiry: '',
            border: 'border-2 border-blue-800 hover:from-blue-500/10 hover:to-blue-400/10 hover:dark:from-blue-800/10 hover:dark:to-blue-700/10',
            colours: 'from-blue-500 to-blue-400 dark:from-blue-800 dark:to-blue-700',
            type: 'cash',
        },
        {
            name: 'nsb',
            niceName: 'National Savings Bank',
            shortName: 'NSB',
            number: '101 XXX XXX 742',
            expiry: '12/26',
            border: 'border-2 border-orange-800 hover:from-orange-400/10 hover:to-orange-500/10 hover:dark:from-orange-800/10 hover:dark:to-orange-700/10',
            colours: 'from-orange-400 to-orange-500 dark:from-orange-800 dark:to-orange-700',
            type: 'master',
        },
        {
            name: 'boc',
            niceName: 'Bank of Ceylon',
            shortName: 'BOC',
            number: '4556 XXXX XXXX 3556',
            expiry: '12/26',
            border: 'border-2 border-yellow-800 hover:from-amber-500/10 hover:to-amber-400/10 hover:dark:from-yellow-800/10 hover:dark:to-yellow-700/10',
            colours: 'from-amber-500 to-amber-400 dark:from-yellow-800 dark:to-yellow-700',
            type: 'visa',
        },
        {
            name: 'cash',
            niceName: 'Cash',
            shortName: 'Cash',
            number: '',
            expiry: '',
            border: 'border-2 border-emerald-800 hover:from-emerald-500/10 hover:to-green-500/10 hover:dark:from-emerald-800/10 hover:dark:to-teal-700/10',
            colours: 'from-emerald-500 to-green-500 dark:from-emerald-800 dark:to-teal-700',
            type: 'cash',
        },
        {
            name: 'fd',
            niceName: 'Fixed Deposit',
            shortName: 'FD',
            number: '',
            expiry: '',
            border: 'border-2 border-fuchsia-800 hover:from-rose-500/10 hover:to-fuchsia-400/10 hover:dark:from-rose-800/10 hover:dark:to-fuchsia-700/10',
            colours: 'from-rose-500 to-fuchsia-400 dark:from-rose-800 dark:to-fuchsia-700',
            type: 'fd',
        },
    ]);

    useEffect(() => {
        getDailySummary();
    }, []);

    const selectWallet = (key) => {
        setSelectedWallet(key);
    };

    return (
        <div className={'sm:w-2/3 md:w-1/2 2xl:w-1/3 mx-auto'}>
            <Header />
            <div className={'container px-3 mx-auto'}>
                <DashboardSummary />
                {/*<section className={'w-auto whitespace-nowrap overflow-x-auto my-8 pb-1'}>
                    <div className={'flex align-top'}>
                        {wallets.map((wallet, index) => (
                            <CreditCard
                                key={index}
                                name={wallet.name}
                                number={wallet.number}
                                expiry={wallet.expiry}
                                colours={
                                    selectedWallet === index
                                        ? wallet.colours + ' border-2 border-transparent'
                                        : wallet.border
                                }
                                type={wallet.type}
                                onClick={() => selectWallet(index)}
                            />
                        ))}
                    </div>
                </section>*/}
                <DashboardTxnDetails className={'pb-24'} />
            </div>
            <Footer />
        </div>
    );
};
