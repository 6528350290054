import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Header from '../../../../template/Header';
import { genRandHex, relativeDay } from '../../../../../helpers/Helpers';
import { SiteSettingsContext } from '../../../../contexts/SiteSettingsContext';

import './categorical-report.scss'
import Footer from '../../../../template/Footer';
import header from '../../../../template/Header';
const CategoricalReport = () => {
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);

    const mask = '***,***.**';
    const negativeStripes = 'repeating-linear-gradient(45deg, #ff2424, #ff2424 12px, #434346 10px, #434346 20px)';

    const [filter, setFilter] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
    });

    const [summary, setSummary] = useState([]);
    const [data, setData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [max, setMax] = useState(0);
    const [monthMax, setMonthMax] = useState(0);

    const getMonthlyData = () => {
        let api_base = process.env.REACT_APP_API_BASE;

        fetch(api_base + 'reports/monthly/')
            .then((res) => res.json())
            .then((summary) => {
                setMonthlyData(summary);
            });
    }

    useEffect(() => {
        getMonthlyData();
    }, []);

    useEffect(() => {
        setMonthMax(Math.max(...monthlyData.map(summary => summary.spent_this_month), ...monthlyData.map(summary => summary.earned_this_month)));
    }, [monthlyData])

    useEffect(() => {
        setMax(Math.max(...data.map(summary => summary.amount)));
    }, [data])

    const getData = (filter) => {
        let api_base = process.env.REACT_APP_API_BASE;

        fetch(api_base + 'reports/categorical/'+filter.year+'/'+filter.month)
        .then((res) => res.json())
        .then((summary) => {
            setSummary(summary);
            let total = summary.report.reduce((total, cat) => total + cat.amount, 0);
            setData([...summary.report, { category: summary.remaining[0], amount: summary.remaining[1], colour: '#29B2D0' }, { category: 'Total expenses', amount: total, colour: '#D61818' }]);
        });
    }

    const getTxns = (filter) => {
        let api_base = process.env.REACT_APP_API_BASE;

        fetch(api_base + 'transaction/'+filter.year+'/'+filter.month)
            .then((res) => res.json())
            .then((summary) => {
                setTransactions(summary);
            });
    }

    useEffect(() => {
        getData(filter);
        getTxns(filter);
    }, [filter]);

    const selectMonth = (index, year, month) => {
        setMonthlyData(monthlyData.map((item, i)=> {
            item.active = false;

            if(index === i){
                return {...item, ['active']: true};
            }

            return item;
        }));

        setFilter({
            year, month, monthStr: monthlyData[index].MonthLongStr, yearStr: monthlyData[index].YearStr
        });
    }

    return (
        <>
            <h1 className={'text-center pt-12 pb-8 text-2xl'}>Categorical Reports</h1>

            <div id={'monthSelectorWrap'} className={'flex pb-3 overflow-x-auto px-3'} style={{ overflowX: 'overlay' }}>
                {
                    monthlyData.map((month, key) => (
                        <div className={'flex flex-col px-2'} onClick={() => selectMonth(key, month.YearStr, month.MonthNum)}>
                            <div className={'flex items-end'} key={key} style={{height: '100px'}}>
                                <div className={'horiz-bar-chart-bar'} style={{
                                    height: (Math.abs(month.earned_this_month) / monthMax * 100) + '%',
                                    background: typeof month.active !== 'undefined' && month.active ? '#29B2D0' : '#2F3D51',
                                }}></div>
                                <div className={'horiz-bar-chart-bar'} style={{
                                    height: (Math.abs(month.spent_this_month) / monthMax * 100) + '%',
                                    background: typeof month.active !== 'undefined' && month.active ? '#D61818' : '#2F3D51',
                                }}></div>
                            </div>
                            <div className={'text-xs mt-1'}>{month.MonthStr}</div>
                        </div>
                    ))
                }
            </div>

            {
                summary.earned && transactions.daily_summary ?
                    <>
                        <h2 className={'text-center text-2xl mt-6'}>{filter.yearStr} {filter.monthStr}</h2>
                        <div className={'text-xs text-gray-400 mt-3 mb-6 grid grid-cols-2 gap-3'}>
                            <span
                                className={
                                    'text-primary bg-gray-100 dark:bg-gray-700 py-1 px-5 rounded-full justify-self-end'
                                }>
                                <i className={'la la-download mr-1'} />
                                LKR {settings.privacy ? parseFloat(transactions.earned_this_month).lkr() : mask}
                            </span>
                            <span className={'bg-gray-100 dark:bg-gray-700 py-1 px-5 rounded-full justify-self-start'}>
                                <i className={'la la-upload mr-1'} /> LKR{' '}
                                {settings.privacy ? parseFloat(transactions.spent_this_month).lkr() : mask}
                            </span>
                        </div>

                        <div className={'px-3'}>
                            {
                                data.map((row, key) => (
                                    <div className={'grid grid-cols-3 gap-3'} key={key}>
                                        <div className={'flex flex-col items-end justify-center'}>
                                            <div key={key}
                                                 className={'bar-chart-bar ' + (row.amount < 0 ? 'bar-negative' : '')}
                                                 style={{
                                                     width: (Math.abs(row.amount) / max * 100) + '%',
                                                     background: row.amount > 0 ? row.colour ? row.colour : '#667FA0' : negativeStripes,
                                                 }}></div>
                                        </div>
                                        <div key={key} className={'col-span-2 flex justify-between items-center gap-3 text-xs'}>
                                            <div className={'cat-label'}>{row.category} </div>
                                            <div> {settings.privacy ? parseFloat(row.amount).lkr() : mask}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <section className={'pt-8 pb-24 px-4'}>
                            {transactions.daily_summary &&
                                Object.keys(transactions.daily_summary).map((day, index) => (
                                    <div className={'grid grid-cols-6'} key={index}>
                                        <div
                                            className={
                                                'text-sm col-span-3  mt-4 pb-2 dark:text-gray-400 text-gray-500'
                                            }>
                                            {relativeDay(day)}
                                        </div>
                                        <div
                                            className={
                                                'text-sm col-span-3 text-right mt-4 pb-2 dark:text-gray-400 text-gray-500'
                                            }>
                                            LKR{' '}
                                            {settings.privacy
                                                ? parseFloat(transactions.daily_summary[day].sub_total).lkr()
                                                : mask}
                                        </div>

                                        <hr className={'col-span-6 dark:border-gray-400 mb-3'} />

                                        {transactions.daily_summary[day].txns.map((txn, index2) => (
                                            <Fragment key={index2}>
                                                <div className={'col-span-6 grid grid-cols-6'}>
                                                    <div className={'text-2xl text-outline cursor-pointer'}>
                                                        {txn.emoji}
                                                    </div>
                                                    <div className={'text-sm col-span-3 py-2'}>
                                                        {txn.description}
                                                    </div>
                                                    <div
                                                        className={
                                                            'text-sm col-span-2 text-right py-2 ' +
                                                            (txn.tx_type === 0 ? 'text-primary' : '')
                                                        }>
                                                        {settings.privacy ? parseFloat(txn.tx_amount).lkr() : mask}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                ))
                            }
                        </section>
                    </>
                    : <></>
            }
        </>

        /* Popular Interior Designs
            * minimalism
            * bohemian
            * scandinavian
            * rusted
            * farmhouse
        * */
    )
}

export default CategoricalReport