import { useState } from 'react';

export const SourcesSelect = ({sources, selectedChoice, setSelectedChoice}) => {
    const isMobile = navigator.userAgentData.mobile;

    return (
        <div className={'flex flex-col gap-2'}>
            {
                sources.map((source, index) => (
                    <div key={index} className={'flex items-center py-3'} id={'source-'+index} onClick={() => setSelectedChoice(index)}>
                        <div className={'text-xl px-4'}>{source.emoji}</div>
                        <div className={'pl-1'}>{source.longName}</div>
                    </div>
                ))
            }
        </div>
    );
};